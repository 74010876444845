@media print {
	@page { 
		margin: 2mm 2mm 2mm 2mm;
		//size: 3.2cm 2.2cm landscape;
	}
	html, body {
		padding: 0;
		margin: 0;
		color: #000;
		font-family: sans-serif;
	}
	.page-header {
		display: none;
	}

	.sticker-print-list {
		padding: 1cm;
		margin: 0;
		width: 100%;
	}
	.sticker-print-buttons {
		display: none;
	}

	.sticker-list {
		width: 100%;
		box-shadow: none;
		padding: 0;
		margin: 0.5;
		float: none;
		text-align: left;
	}
	.sticker-list > li {
		border: 1px solid #666;
		display: inline-block;
		page-break-inside: avoid;
	}


	.pe-print-display {
	}
	.pe-print-buttons {
		display: none;
	}

  .mc-print-display {
    font-size: 12px;
    line-height: 1.4em;

    table {
      margin: 0 0 5px;
    }
  }
}